"use strict";

import Vue from 'vue';
import axios from "axios";

axios.defaults.headers.common['x-data-key'] = '';
axios.defaults.timeout = 20000;

const _axios = axios.create({
    //Prod
    baseURL: "https://core.bpp.com.br/external"
    //Homolog
    //baseURL: "https://core.hom-bpp.com.br/external"
    //local
    //baseURL: process.env.baseURL || process.env.apiUrl || "http://localhost:8817"
});

const _getIp = async() => {
    return (new Promise((resolve) => {
        const ajax = new XMLHttpRequest();
        ajax.open("GET", "https://api.ipify.org/?format=json", true);
        ajax.send();
        ajax.onreadystatechange = () => {
            try {
                if (ajax.readyState == 4 && ajax.status == 200) {
                    resolve(JSON.parse(ajax.responseText).ip);
                }
            } catch (e) {
                console.error(e);
                resolve({ ip: '' });
            }
        }
    }));
};

_axios.interceptors.request.use(
    async(config) => {
        config.headers.common['Ip'] = await _getIp();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Plugin.install = (Vue) => {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin);
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $ip: {
            get() {
                return _getIp();
            }
        }
    });
});

export default Plugin;
