"use strict";

import Vue from 'vue';

const exceptionConstants = (e, ref) => {
	switch (e.toString()) {
		case 'Error: Request failed with status code 400':
			switch (ref) {
				case 'login':
					return 'Falha ao efetuar Login.';
			
				default:
					return e;
			}
		default:
			return e;
	}
};

Vue.mixin({
	created: () => {
        // this.$root.exceptionConstants;
    },
    data: () => {
        return {
            exceptionConstants
        }
    }
});