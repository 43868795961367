import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.mixin({
    created: () => { },
    data: () => {
        return {
            loading: false,
            respRecaptcha: null
        }
    }
});
Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'home',
    component: () =>
        import('../views/Home.vue')
}, {
    path: '/legacy',
    name: 'Legacy',
    component: () =>
        import('../views/Legacy.vue')
}, {
    path: '/fenix',
    name: 'Fenix',
    component: () =>
        import('../views/Fenix.vue')
}, {
    path: '/legacy',
    name: 'Legacy',
    component: () =>
        import ('../views/Legacy.vue')
}, {
    path: '/:key',
    name: 'home',
    component: () =>
        import('../views/Home.vue')
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;