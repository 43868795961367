import '@babel/polyfill';
import 'mutationobserver-shim';
import './plugins/bootstrap-vue';
import './plugins/axios';
import './plugins/bootstrap-vue';
import './plugins/bootstrap-vue';
import './plugins/background';
import "./plugins/crypto";
import './plugins/exception-constants';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueTheMask from 'vue-the-mask';
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from 'vue-sweetalert2';
//import { VueReCaptcha } from 'vue-recaptcha-v3';
//import { siteKey } from "../package.json";
//Vue.use(VueReCaptcha, { siteKey });

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));
Vue.use(VueTheMask);
Vue.use(require('vue-moment'));
Vue.use(VueSimpleAlert, { title: "Vue Simple Alert", width: "420px" });
Vue.use(VueSweetalert2);


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');